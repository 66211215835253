import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { IUser } from '@core/interfaces';
import { UsersService } from '@core/services';
import { AuthService } from '@auth/services';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';

enum contactModalType {
  CONFIRMATION = 'CONFIRMATION',
  REQUEST_DOCUMENTED = 'REQUEST_DOCUMENTED',
  CONTACT_DETAILS = 'CONTACT_DETAILS',
  REQUEST_FORBIDDEN = 'REQUEST_FORBIDDEN',
}
@Component({
  selector: 'app-mobile-talent-contact-modal',
  templateUrl: './mobile-talent-contact-modal.component.html',
  styleUrls: ['./mobile-talent-contact-modal.component.scss'],
})
export class MobileTalentContactModalComponent implements OnInit {
  @Input()
  public talent: IUser;
  @Input()
  public applicantId?: string;

  @ViewChild('appMobileTalentContactModal') public modalComponent: MobileModalDialogComponent;

  public authenticated: IUser;
  public contactModalType = contactModalType;
  public contactModal: contactModalType = contactModalType.CONFIRMATION;

  constructor(private userService: UsersService, public authService: AuthService, private store: Store) {}

  ngOnInit(): void {}

  async openDialog() {
    return await this.modalComponent.open().then(() => {});
  }

  public confirmContactDetails() {
    this.contactModal = contactModalType.REQUEST_DOCUMENTED;
    setTimeout(() => {
      this.userService.getContact(this.talent.id, this.applicantId).subscribe(
        (result) => {
          this.contactModal = contactModalType.CONTACT_DETAILS;
          this.talent = result;
        },
        (error) => {
          this.contactModal = contactModalType.REQUEST_FORBIDDEN;
        },
      );
    }, 2000);
  }

  public upgradeMembership(): void {
    this.modalComponent.close();
    this.store.dispatch(new Navigate(['/account/details/membership']));
  }
}
