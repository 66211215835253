export const environment = {
  production: true,
  flavor: 'idstg',
  baseUrl: 'https://dev.casterbee.co.id',
  apiBaseUrl: 'https://api-stg.casterbee.co.id',
  cookieDomain: 'www.dev.casterbee.co.id',
  vat: 0.21,
  unleashKey: 'default:development.04b504ac-6a1a-4755-af15-ed07f621c33c',
  currency: 'Rp',
  locale: 'ID',
};
